import { Component, OnInit, OnDestroy, Injector, ViewChild } from '@angular/core';
import { forkJoin, lastValueFrom, Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { GallerySeatComponent } from './gallery-seat.component';

// models
import { Auction } from '../../shared/models/auction';
import { AuctionClusterPermissionEnum } from '../../shared/models/user-permissions';

import { AuctionClusterBuyer } from '../shared/models/auction-cluster-buyer';
import { GallerySeat } from '../shared/models/gallery-seat';

// services
import { LanguageService } from '../../shared/services/language.service';

import { AuctionClusterAuctionService } from '../shared/services/auction-cluster-auction.service';
import { AuctionClusterBuyerService } from '../shared/services/auction-cluster-buyer.service';
import { GalleryService } from '../shared/services/gallery.service';
import { ArdsInfo, GalleryType } from '../shared/models/ards-info';
import { ClockService } from '../../shared/services/clock.service';
import { Clock } from '../../shared/models/clock';


@Component({
  selector: 'gallery-component',
  templateUrl: 'gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent extends FullListComponent<GallerySeat, GallerySeatComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: GallerySeatComponent;

  clusters: Array<GallerySeat> = [];
  selectedAuction: number = -1;
  auctions: Array<Auction> = [];
  buyers: Array<AuctionClusterBuyer> = [];
  selectedClocks: Array<Clock> = [];
  ardsInfos: Array<ArdsInfo> = [];
  ardsInfo: ArdsInfo = new ArdsInfo();

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  private _ardsInfoSubscription: Subscription;
  private _authenticatedSubscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: GalleryService,
    private languageService: LanguageService,
    private auctionService: AuctionClusterAuctionService,
    private buyerService: AuctionClusterBuyerService,
    private clockService: ClockService
  ) {
    super(injector, GallerySeat);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.title.set('GALLERY.TITLE');
    this.setTranslations('GALLERY');

    this._ardsInfoSubscription = this.dataService.ardsInfo.subscribe((info: ArdsInfo) => {
      var selectedArdsInfo = this.ardsInfos.find(f => f.clockId === info.clockId);
      if (selectedArdsInfo) {
        selectedArdsInfo.galleryType = info.galleryType ?? selectedArdsInfo.galleryType;
        selectedArdsInfo.version = info.version;
        selectedArdsInfo.connected = info.connected;
        selectedArdsInfo.auctionroomBuyers = info.auctionroomBuyers;
      }
      this.ardsInfo = selectedArdsInfo;
      if (this.isCTIF) {
        this.fillCTIFGallery();
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
    this._ardsInfoSubscription.unsubscribe();
    this._authenticatedSubscription.unsubscribe();
    this.dataService.stop();
  }

  async getData() {
    this.spinner.show();

    forkJoin(
      this.auctionService.getAuctionsForPermissions(this.id,
        [AuctionClusterPermissionEnum.GalleryManagement]),
      this.buyerService.getBuyers(this.id)

    ).subscribe(async result => {

      this.auctions = result[0];
      this.buyers = result[1];
      if (this.auctions.length > 0) {
        if (this.selectedAuction == -1 || !this.selectedAuction)
          this.selectedAuction = this.auctions[0].auctionId;

        this.dataService.getGallerySeats(this.selectedAuction)
          .subscribe(res => {
            this.items = res;
            this.spinner.hide();
            this.matchAuctionName();
            this.matchBuyerNumber();
          }, error => {
            this.errorService.show(error);
            this.spinner.hide();
          });

        if(this.hasGallery)
          await this.setupSignalR();
      }
      else {
        this.spinner.hide();
      }
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });
  }

  get isCTIF(): boolean {
    return this.ardsInfo?.galleryType === GalleryType.CTIF;
  }

  get hasGallery(): boolean {
    var auction = this.auctions.find(f => f.auctionId === this.selectedAuction);
    return auction?.galleryType != GalleryType.None;
  }

  edit = (e: any) => {
    if (this.selectedAuction) {
      let selectedSeatNumber = this.isCTIF ? e.row.data.seatNumber : null;
      this.detailsComponent.modalTitle = this.translations.EDIT;
      this.detailsComponent.open(this.items, e.row.data.gallerySeatId, this.selectedAuction, this.auctions, this.buyers, selectedSeatNumber, e.row.data.buyerId, this.selectedClocks);
    }
  }

  add() {
    if (this.selectedAuction) {
      this.detailsComponent.modalTitle = this.translations.ADD_NEW;
      this.detailsComponent.open(this.items, null, this.selectedAuction, this.auctions, this.buyers);
    }
  }

  private fillCTIFGallery() {
    for (var i = 1; i <= this.ardsInfo.gallerySize; i++) {
      var existing = this.items.find(f => f.seatNumber === i);
      var buyerAtSeat = this.ardsInfo.auctionroomBuyers?.find(f => f.seatNumbers.includes(i));

      if (!existing) {
        var item = new GallerySeat();
        item.seatNumber = i;
        item.auctionId = this.selectedAuction;
        item.buyerId = buyerAtSeat?.buyerId;
        item.buyerNumber = buyerAtSeat ? this.buyers.find(f => f.buyerId === buyerAtSeat.buyerId)?.buyerNumber : '';
        item.auctionId = this.selectedAuction;
        item.auctionName = this.auctions.find(f => f.auctionId === item.auctionId).name;
        item.reserved = false;
        this.items.push(item);
      }
      else {
        if (!existing.reserved && buyerAtSeat == null) {
          existing.buyerNumber = null;
          existing.buyerId = null;
        }
        else {
          if (existing.reserved && buyerAtSeat != null) {
            if (buyerAtSeat.buyerId != existing.buyerId) {
              existing.wrongBuyerAtSeat = true;
            }
            else {
              existing.expectedBuyerAtReservedSeat = true;
            }
          }

          if (buyerAtSeat != null)
            existing.buyerId = buyerAtSeat?.buyerId;

          existing.buyerNumber = this.buyers.find(f => f.buyerId === existing.buyerId)?.buyerNumber;
        }
      }
    }
    this.items.sort((a, b) => a.seatNumber - b.seatNumber);
  }

  private async setupSignalR() {
    this._authenticatedSubscription = this.dataService.isAutheticated.subscribe((success: boolean) => {
      if (success) {
        this.getArdsInfo();
      }
    });
    await this.dataService.initSignalR();
  }

  private async getArdsInfo() {
    let auction = this.auctions.find(f => f.auctionId === this.selectedAuction);
    this.selectedClocks = await lastValueFrom(this.clockService.getAuctionClocks(this.selectedAuction));
    this.ardsInfos = [];
    this.selectedClocks.forEach(clock => {
      var ardsInfo = new ArdsInfo();
      ardsInfo.clockId = clock.clockId;
      ardsInfo.clockName = clock.name;
      ardsInfo.galleryType = auction.galleryType;
      ardsInfo.gallerySize = auction.gallerySize
      this.ardsInfos.push(ardsInfo);

      this.dataService.getARDSInfo(clock.clockId)
    });

    this.ardsInfo = this.ardsInfos[0];
    if (this.isCTIF) {
      this.fillCTIFGallery();
    }
  }

  private matchAuctionName() {
    this.items.forEach(item => {
      item.auctionName = this.auctions.find(f => f.auctionId === item.auctionId).name;
    });
  }

  private matchBuyerNumber() {
    this.items.forEach(item => {
      item.buyerNumber = this.buyers.find(f => f.buyerId === item.buyerId).buyerNumber;
    });
  }

  deleteItem = (e: any) => {
    if (this.selectedAuction) {
      this.itemIdToDelete = e.row.data.gallerySeatId;
      this.confirmation.opened = true;
    }
  }

  deleteSelected() {
    this.spinner.show();
    let itemToDelete: GallerySeat;
    this.items.forEach((gallerySeatItem) => {
      if (gallerySeatItem.gallerySeatId == this.itemIdToDelete) {
        itemToDelete = gallerySeatItem;
      }
    })
    this.dataService.delete(itemToDelete.auctionId, this.itemIdToDelete)
      .subscribe((locations: Array<GallerySeat>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  getSelectedGallerySeats() {
    if (this.selectedAuction) {
      this.dataService.getGallerySeats(this.selectedAuction).subscribe(seats => {
        this.items = seats;
        this.matchAuctionName();
        this.matchBuyerNumber();

        // also fetch ARDS info
        this.getArdsInfo();
      });
    } else {
      this.getData();
    }
  }

  onRowPrepared(e: any) {
    if (e.rowType === 'data') {
      if (e.data.wrongBuyerAtSeat) {
        e.rowElement.style.color = 'red';
      }
      else if(e.data.expectedBuyerAtReservedSeat) {
        e.rowElement.style.color = 'green';
      }
    }
  }

  onDetailsComponentClosed(isDataChanged: boolean) {
    if (isDataChanged) {
      if (this.selectedAuction) {
        this.dataService.getGallerySeats(this.selectedAuction)
        .subscribe(res => {
          this.items = res;
          this.spinner.hide();

          this.fillCTIFGallery();
          this.matchAuctionName();
          this.matchBuyerNumber();

        }, error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
      } else {
        this.getData();
      }
    }
  }
}
